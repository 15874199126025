import React from 'react';
import {Switch} from "react-router";

import asyncComponent from "lib/asyncComponent";
import PublicRoute from "./route/Public";
import PrivateRoute from "./route/Private";
import OnlyPublicRoute from "./route/OnlyPublic";
import Default from "./layout/Default";
import SideNav from "./layout/SideNav";
import Settings from "./Settings";
import DevBanner from './DevBanner';
import { FeatureProvider } from 'components/common/FeatureProvider';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from 'config/muiTheme';

const Home = asyncComponent(_ => import("./Home"));
const WelcomeBack = asyncComponent(_ => import("./WelcomeBack"));
const NotFound = asyncComponent(_ => import("./error/NotFound"));
const Forbidden = asyncComponent(_ => import("./error/Forbidden"));
const Unavailable = asyncComponent(_ => import("./error/Unavailable"));
const Unauthorized = asyncComponent(_ => import("./error/Unauthorized"));
const SubmissionFlow = asyncComponent(_ => import("./SubmissionFlow"));
const AccountDetails = asyncComponent(_ => import("./SubmissionFlow/AccountDetails"));
const SearchDashboard = asyncComponent(_ => import("./Dashboard"));
const MyWorkViewDashboard = asyncComponent(_ => import("./Dashboard/MyWorkView/old"));
const TasksPage = asyncComponent(_ => import("./TasksPage"));
const Notifications = asyncComponent(_ => import("./DashboardNotifications"));
const Complaints = asyncComponent(_ => import("./Complaints"));
const Admin = asyncComponent(_ => import("./Admin"));
const Reports = asyncComponent(_ => import("./Reports"));
const Downloads = asyncComponent(_ => import("./Downloads"));
const SignUp = asyncComponent(_ => import("./SignUp"));
const SignIn = asyncComponent(_ => import("./SignIn"));
const SignUpConfirmed = asyncComponent(_ => import("./SignUpConfirmed"));
const OSSignUp = asyncComponent(_ => import("./OSSignUp"));
const ConfirmPasscode = asyncComponent(_ => import("./ConfirmPasscode"));
const PasscodeAccountCompletion = asyncComponent(_ => import("./PasscodeAccountCompletion"));
const ResendPasscodeConfirmation = asyncComponent(_ => import("./ResendPasscodeConfirmation"));
const ResendSignUpConfirmation = asyncComponent(_ => import("./ResendSignUpConfirmation"));
const ResendActivationEmail = asyncComponent(_ => import("./ResendActivationEmail"));
const OSCreateCase = asyncComponent(_ => import("./OSCreateCase"));
const CreateWithoutUser = asyncComponent(_ => import("./CreateWithoutUser"));
const ForgotPassword = asyncComponent(_ => import("./ForgotPassword"));
const ResetPassword = asyncComponent(_ => import("./ResetPassword"));
const SignOut = asyncComponent(_ => import("./SignOut"));
const Preferences = asyncComponent(_ => import("./Preferences"));

export default () => (
  <ThemeProvider theme={muiTheme}>
    <FeatureProvider>
      <DevBanner />
      <Settings />
      <Switch>
        <OnlyPublicRoute layout={Default} path="/signup/" component={SignUp} />
        <OnlyPublicRoute layout={Default} path="/signup-energy" component={SignUp} />
        <OnlyPublicRoute layout={Default} path="/signup-comms" component={SignUp} />
        <OnlyPublicRoute layout={Default} path="/signin" component={SignIn} />
        <OnlyPublicRoute layout={Default} path="/forgot-password" component={ForgotPassword} />
        <OnlyPublicRoute layout={Default} path="/reset-password/:token" component={ResetPassword} />
        <OnlyPublicRoute layout={Default} path="/signup-confirmed/:token" component={SignUpConfirmed} />
        <OnlyPublicRoute
          layout={Default}
          path="/resend-signup-confirmation"
          component={ResendSignUpConfirmation}
        />
        <OnlyPublicRoute layout={Default} path="/create-password/:token" component={ResetPassword} />
        <OnlyPublicRoute
          layout={Default}
          path="/passcode-confirmation/:token"
          component={ConfirmPasscode}
        />
        <OnlyPublicRoute
          layout={Default}
          path="/passcode-account-completion/:token"
          component={PasscodeAccountCompletion}
        />
        <OnlyPublicRoute
          layout={Default}
          path="/resend-passcode-confirmation"
          component={ResendPasscodeConfirmation}
        />
        <OnlyPublicRoute
          layout={Default}
          path="/resend-activation-email"
          component={ResendActivationEmail}
        />

        <PrivateRoute path="/signout" component={SignOut} layout={SideNav} />
        <PrivateRoute path="/complaints" component={Complaints} layout={SideNav} />
        <PrivateRoute path="/dashboard" component={SearchDashboard} layout={SideNav} />
        <PrivateRoute path="/dashboard/myworkview" component={MyWorkViewDashboard} layout={SideNav} />
        <PrivateRoute path="/tasks" component={TasksPage} permission="view:tasks" layout={SideNav} />
        <PrivateRoute path="/notifications" component={Notifications} layout={SideNav} />
        <PrivateRoute path="/reports" component={Reports} layout={SideNav} />
        <PrivateRoute path="/welcome_back/:complaintId" component={WelcomeBack} layout={Default} />
        <PrivateRoute path="/admin" component={Admin} permission="view:admin" layout={Default} />
        <PrivateRoute
          path="/ossignup"
          component={OSSignUp}
          permission="view:ossignup"
          layout={Default}
        />
        <PrivateRoute
          path="/create-new-case"
          component={OSCreateCase}
          permission="view:ossignup"
          layout={Default}
        />
        <PrivateRoute
          path="/create-without-consumer"
          component={CreateWithoutUser}
          permission="view:sf:without_user"
          layout={Default}
        />
        <PrivateRoute
          path="/account-holder-details"
          component={AccountDetails}
          permission="view:sf:without_user"
          layout={Default}
        />
        <PrivateRoute path="/preferences" component={Preferences} layout={Default} />
        <PrivateRoute path="/apply" component={SubmissionFlow} redirectTo="/signup" layout={Default} />
        <PrivateRoute path="/downloads" component={Downloads} />

        <PublicRoute layout={Default} path="/" exact component={Home} />
        <PublicRoute path="/unauthorized" exact component={Unauthorized} />
        <PublicRoute path="/forbidden" exact component={Forbidden} />
        <PublicRoute path="/unavailable" exact component={Unavailable} />
        <PublicRoute path="/not-found" exact component={NotFound} />

        <PublicRoute component={NotFound} />
      </Switch>
    </FeatureProvider>
  </ThemeProvider>
);

import { PropsWithChildren, useEffect, useState }        from 'react';
import getFeatureFlag                                    from 'fetchers/featureFlags/getFeatureFlag';
import LoadingSVG                                        from 'components/common/LoadingSVG';

export const SUBMISSION_ADDITIONAL_SUPPORT = 'submission_flow_additional_support_enabled'
export const MANUAL_TASK_CREATION_ENABLED = 'manual_task_creation_enabled'
export const CHANGE_COMPANY_ENABLED = 'change_company_enabled'
export const NATURE_OF_BUSINESS = 'nature_of_business_enabled'
export const NEW_DASHBOARD_TOGGLE = 'new_dashboard_toggle_enabled'

export type FeatureConfig = Record<Feature, boolean>;
export const defaultFeatures = {
  [SUBMISSION_ADDITIONAL_SUPPORT]: false,
  [MANUAL_TASK_CREATION_ENABLED]: false,
  [CHANGE_COMPANY_ENABLED]: false,
  [NATURE_OF_BUSINESS]: true,
  [NEW_DASHBOARD_TOGGLE]: false,
}

export async function getFeatureValues(defaultValues: FeatureConfig) {
  const features = { ...defaultValues };

  const requests = Object.entries(defaultValues).map(([feature, defaultValue]) => new Promise((resolve) => {
      getFeatureFlag(feature, defaultValue)
        .then((value) => {
          resolve({ [feature]: value })
        })
    }))

  const featuresArray = await Promise.all(requests)
  for (const obj of featuresArray) {
    Object.assign(features, obj);
  }

  return features;
}

// Hold features in memory
type IFeatureStore = {
  [key in Feature]: boolean;
};
let featureStore: IFeatureStore = {} as IFeatureStore

export const FeatureProvider = ({ children }: PropsWithChildren) => {
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const authToken = localStorage.getItem('oso-auth');
  const timeoutInMinutes = 10;
  const timeoutToken = Math.floor(new Date().getTime() / (1000 * 60 * timeoutInMinutes))

  useEffect(() => {
    if (authToken) {
      getFeatureValues(defaultFeatures)
        .then((features) => {
          featureStore = features;
        })
        .finally(() => {
          setFeaturesLoaded(true);
        })
    } else {
      featureStore = {} as IFeatureStore
      setFeaturesLoaded(false);
    }
  }, [authToken, timeoutToken])

  if (!authToken) {
    return children;
  }

  if (!featuresLoaded) {
    return (
      <div className="container mt-10">
        <LoadingSVG size={32} delay={500} />
      </div>
    );
  }

  return children
}

// Features hook, can be used in functional components
type Feature = keyof typeof defaultFeatures;
export function getFeature(featureName: Feature) {
  return featureStore[featureName];
}

// To be used in tests to mock the value of a feature
export function mockFeature(featureName: Feature, value: never) {
  featureStore[featureName] = value
}
